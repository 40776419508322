import React from 'react';
import { RecoilRoot } from 'recoil';
//import { Layout } from "./src/components/layout";

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
//const wrapPageElement = ({ element, props }) => (
//    <RecoilRoot>
//      <Layout { ...props } >
//        { element }
//      </Layout>
//    </RecoilRoot>
//);

const wrapPageElement = ({ element, props }) => (
    <RecoilRoot>
        { element }
    </RecoilRoot>
);

export default wrapPageElement;
